<template>
  <div>
    <div class="product-list" v-if="!submitted">
      <div v-if="listItemsCheck">
        <product-table-item
          v-for="item in listItems"
          :key="item.key"
          :item="item"
          :buy-action="buyProduct"
          :request-quote="requestQuote"
          :request-type="requestType"
          :single-profile="singleProfile"
          :bought-item="checkItem(item)"
        />
      </div>
      <div v-else>
        <p>
          <em>
            Nessun prodotto disponibile
          </em>
        </p>
      </div>
    </div>
    <product-wizard-modal
      :id="wizardModalId"
      :request-type="requestType"
      :request-product="itemKey"
      :request-product-item="productItem"
      :product-description="productDescription"
      :entity-type="entityTypeData"
      :profile-entity="profileEntity"
      :has-product-search="extendedSearch"
      :extended-search-data="extendedSearchData"
    />
    <quote-modal
      :id="quoteModalId"
      :request-type="requestType"
      :request-product="itemKey"
      :product-description="productDescription"
      :entity-type="entityTypeData"
      :profile-entity="profileEntity"
      :quote-info="quoteInfo"
    />
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty, isNotExist } from '@/utils/validators';

const ProductTableItem = () => import('./ProductTableItem.vue');
const ProductWizardModal = () => import('./ProductWizardModal.vue');
const QuoteModal = () => import('./QuoteModal.vue');

export default {
  name: 'ProductTable',
  components: { ProductTableItem, ProductWizardModal, QuoteModal },
  props: {
    list: Array,
    requestType: String,
    singleProfile: Boolean,
    entityType: String,
    profileEntity: Object,
  },
  data() {
    return {
      listItems: this.list || [],
      itemKey: null,
      productItem: null,
      productDescription: null,
      searchBase: {
        search: '',
        page: 0,
        size: 100,
        sort: [{ field: 'lastModified', direction: 'DESC' }],
        filters: [
          {
            field: 'status',
            operation: 'EQ',
            value: 'COMPLETED',
          },
          {
            field: 'referentInfo.vatNumber',
            operation: 'EQ',
            value: this.$route.params.entityTax,
          },
        ],
      },
      entityTypeData: this.entityType || '',
      wizardModalId: `wizardModal-${this.requestType}`,
      quoteModalId: `quoteModal-${this.requestType}`,
      quoteInfo: null,
      submitted: true,
      extendedSearch: false,
      extendedSearchData: {},
    };
  },
  computed: {
    // wizardModalId() {
    //   // console.log('checking prods', this.list, this.singleProfile);
    //   return 'wizardModal';
    // },
    userAnalysisList() {
      return this.singleProfile ? this.$store.getters['analysis/userAnalysisList'] : [];
    },
    listItemsCheck() {
      return isNotEmpty(this.listItems);
    },
  },
  mounted() {
    this.fetchAnalysisList(true);
  },
  methods: {
    buyProduct(item) {
      console.log('buy key', item);
      this.itemKey = item?.key;
      this.productItem = item;
      this.productDescription = item?.value;
      this.extendedSearch = !!isNotEmpty(item?.additionalInfos?.search);
      if (isNotEmpty(item?.additionalInfos?.search)) {
        this.extendedSearchData = item.additionalInfos.search;
        // console.log('searchData', item.additionalInfos.search);
      }
      // console.log('item?.additionalInfos?.search', item?.additionalInfos?.search);
      this.$bvModal.show(this.wizardModalId);
    },
    requestQuote(item) {
      // console.log('buy key', key);
      this.itemKey = item?.key;
      this.productDescription = item?.value;
      this.quoteInfo = item?.additionalInfos?.info;
      // console.log('this.quoteInfo', item, this.item.additionalInfos.info, this.quoteInfo);
      this.$bvModal.show(this.quoteModalId);
    },
    fetchAnalysisList(reload, nextPage) {
      // console.log('list fectad', this.searchBase, nextPage);
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let entType = '';
      if (isNotEmpty(currentProfile)) {
        this.entityId = currentProfile.companyId;
        entType = 'company';
      } else {
        entType = 'person';
        this.entityId = this.$store.getters['subject/currentPerson']?.personId;
      }

      if (isNotExist(this.entityId)) return;

      const search = { ...this.searchBase };

      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['analysis/userAnalysisPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      this.submitted = true;
      this.$store.dispatch('analysis/searchUserAnalysis', {
        entityId: this.entityId,
        entityType: entType,
        search,
        reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    checkItem(item) {
      if (!this.userAnalysisList) return null;
      // if (this.userAnalysisList.find((obj) => obj.requestProduct === item.key)) {
      //   console.log(
      //     'checkItem',
      //     this.userAnalysisList.find((obj) => obj.requestProduct === item.key),
      //   );
      // }
      // console.log('checkitem', item.key, this.userAnalysisList.filter((obj) => obj.requestProduct === item.key));
      return this.userAnalysisList.find((obj) => obj.requestProduct === item.key);
    },
  },
};
</script>

<style scoped>

</style>
